// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/context/ViewContext.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/context/ViewContext.tsx");
  import.meta.hot.lastModified = "1730584816000";
}
// REMIX HMR END

import React, { createContext, useCallback, useContext, useEffect, useReducer } from "react";
import { useLocation } from "@remix-run/react";
const SUBUSERPATH = "/home/subuser";
const ViewStateContext = createContext(undefined);
export function useViewContext() {
  _s();
  const context = useContext(ViewStateContext);
  if (!context) {
    throw new Error('viewStateContext must be used within a ViewStateProvider');
  }
  return context;
}
_s(useViewContext, "b9L3QQ+jgeyIrH0NfHrJ8nn7VMU=");
const viewStateReducer = (state, action) => {
  switch (action.type) {
    case "SET_VIEW_STATE":
      return {
        ...state,
        viewState: action.payload
      };
    case "SET_HEADER_HEIGHT":
      return {
        ...state,
        headerHeight: action.payload
      };
    case "SET_OPEN_SNACKBAR":
      return {
        ...state,
        openSnackbar: true,
        alertProps: action.payload
      };
    case "SET_CLOSE_SNACKBAR":
      return {
        ...state,
        openSnackbar: false,
        alertProps: {
          title: "",
          message: ""
        }
      };
    case "SET_OPEN_DRAWER":
      return {
        ...state,
        openDrawer: action.payload
      };
    default:
      return state;
  }
};
export function ViewStateProvider({
  children
}) {
  _s2();
  const {
    pathname
  } = useLocation();
  const [state, dispatch] = useReducer(viewStateReducer, {
    viewState: false,
    headerHeight: 0,
    openSnackbar: false,
    alertProps: {
      title: "",
      message: ""
    },
    openDrawer: false
  });
  const handleViewChange = useCallback(state => {
    dispatch({
      type: "SET_VIEW_STATE",
      payload: state
    });
  }, []);
  const handleHeaderHeight = useCallback(height => {
    dispatch({
      type: "SET_HEADER_HEIGHT",
      payload: height
    });
  }, []);
  const handleAlert = useCallback((title, message, props) => {
    dispatch({
      type: "SET_OPEN_SNACKBAR",
      payload: {
        title,
        message,
        props
      }
    });
  }, []);
  const handleCloseAlert = useCallback(() => {
    dispatch({
      type: "SET_CLOSE_SNACKBAR"
    });
  }, []);
  const handleDrawer = useCallback(state => {
    dispatch({
      type: "SET_OPEN_DRAWER",
      payload: state
    });
  }, []);
  useEffect(() => {
    dispatch({
      type: "SET_VIEW_STATE",
      payload: pathname === SUBUSERPATH ? false : true
    });
    dispatch({
      type: "SET_OPEN_DRAWER",
      payload: false
    });
  }, [pathname]);
  return <ViewStateContext.Provider value={{
    ...state,
    handleViewChange,
    handleHeaderHeight,
    handleAlert,
    handleCloseAlert,
    handleDrawer
  }}>
      {children}
    </ViewStateContext.Provider>;
}
_s2(ViewStateProvider, "vvW1icaMotgI8zJ5NewUL3u285g=", false, function () {
  return [useLocation];
});
_c = ViewStateProvider;
var _c;
$RefreshReg$(_c, "ViewStateProvider");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;